<template>
  <div class="field">
    <label for="icon" class="mb-1">Icon</label>
    <Dropdown id="icon" v-model="iconData" :filter="true" placeholder="Icon wählen" :options="getIcons" optionLabel="key" optionValue="icon" @change="changeIcon" :filterFields="filterFields" filterMatchMode="contains">
      <template #value="slotProps">
        <div>
          <div class="flex align-items-center justify-content-between">
            <i :class="`${getOneIcon(slotProps.value).icon} fs-xlarge icon-sm flex align-items-center`"></i>
            <span class="text-md ml-1 flex align-items-center"> {{ getOneIcon(slotProps.value).name }}</span>
            <span class="text-small ml-1 flex align-items-center"> {{ getOneIcon(slotProps.value).key }}</span>
          </div>
        </div>
      </template>
      <template #option="slotProps">
        <div class="flex align-items-center justify-content-between">
          <i :class="`${slotProps.option.icon} fs-xlarge icon-sm flex align-items-center`"></i>
          <span class="text-md ml-1 flex align-items-center"> {{ slotProps.option.name }}</span>
          <span class="text-small ml-1 flex align-items-center"> {{ slotProps.option.key }}</span>
        </div>
      </template>
    </Dropdown>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "iconDropdown",
  setup() {},
  components: {
  },
  emits: ['changeIcon'],
  props: {
    icon: {
      type: String,
      required: false
    },
  },
  data() {
    return {
      loading: false,
      iconData: null,
      filterFields: ["key", "icon", "name", "group", "groupName"],
    }
  },
  watch: {

  },
  computed: {
    ...mapGetters({
      getIcons: 'types/getIcons',
      getIconGroups: 'types/getIconGroups',
      getOneIconGroup: 'types/getOneIconGroup',
      getOneIcon: 'types/getOneIcon',
    }),
    icon1: function() {
      if (this.icon) return this.icon;
      else return '';
    },
  },
  created() {
    this.$store.dispatch('types/loadIconTypes');
  },
  mounted() {
    this.iconData = this.icon;
    
  },
  beforeUnmount() {
    this.loading = null;
    this.iconData = null;
    this.filterFields = null;
  },
  methods: {
    changeIcon(e) {
      this.$emit('changeIcon', e.value);
    },
    addIconGroupsToFilter() {

    },
  },
});
</script>

<style lang="scss" scoped>
</style>